import React , { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Constant from "../../../../config/constans";
import { RightOutlined } from '@ant-design/icons';
import { Typography, Avatar, Flex, Space, Card, Image, Empty } from 'antd';
import { Link } from "react-router-dom";

const { Text } = Typography;
const { Meta } = Card;

const LandingPagesHome = () => {
    const [dataAllPertandingan, setDataAllPertandingan] = useState(null)

    useEffect(() => {
        getListAllJadwalPertandingan()
    },[]);

    const getListAllJadwalPertandingan = () => {
        const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_ALL_JADWALPERTANDINGAN;
        axios
        .get(URL)
        .then((response) => {
        if (response.status === 200) {
            setDataAllPertandingan(response.data.Data);
            console.log(response.data.Data)
        }
        })
        .catch((error) => {
        console.log(error)
        });
    }

    return (
        <Flex direction="vertical" className="web-content-wrapper-card-jadwal-pertandingan" size={"large"}>
            {dataAllPertandingan ? dataAllPertandingan.map(item => (
                <Card 
                    className="web-content-card-jadwal-pertandingan"
                    title={
                        <Link to={`/line-up`} state={{item, path: ''}}>
                            <Flex justify="space-between">
                                <Meta
                                    avatar={
                                        <Avatar src={item.image_sub_kategori_game} />
                                    }
                                    title={item.nama_kategori_game}
                                    description={item.nama_season}
                                />
                                <RightOutlined style={{color:'#ffffff'}} />
                            </Flex>
                        </Link>
                    }
                >
                    <Flex align="center">
                    <Text>{item.jam_tanding}</Text>
                    <Flex vertical gap={"middle"} style={{width:'calc(100% - 55px)'}}>
                        <Flex justify="space-between">
                        <Flex align="center" className="web-content-card-team">
                            <Image
                                width={40}
                                height={40}
                                preview={false}
                                src={item.image_team_1}
                            />
                            <Text>{item.nama_team_1}</Text>
                        </Flex>
                        {item.flag_live !== '0' ? <Text className="web-content-card-team-score">{item.score1}</Text> : null }
                        </Flex>
                        <Flex justify="space-between">
                        <Flex align="center" className="web-content-card-team">
                            <Image
                                width={40}
                                height={40}
                                preview={false}
                                src={item.image_team_2}
                            />
                            <Text>{item.nama_team_2}</Text>
                        </Flex>
                        {item.flag_live !== '0' ? <Text className="web-content-card-team-score">{item.score2}</Text> : null }
                        </Flex>
                    </Flex>
                    </Flex>
                    <Space className="status-pertandingan">
                    <Text >
                        {item.flag_live === "0" ? moment(item.tanggal_tanding).format('DD MMM YYYY') : item.flag_live === "1" ? "LIVE" : "END GAME"}
                    </Text>
                    </Space>
                </Card>
            )) : <Flex justify="center" style={{width:'100%'}}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Flex>}
        </Flex>
    )
}

export default LandingPagesHome;