const Constant = {
    // URL_MASTER_PATH: 'http://104.248.150.29/',
    URL_MASTER_PATH: 'http://backend.scorequest.cloud/',
    // URL_MASTER_PATH: 'http://152.42.174.2/',


    // Register
    URL_POST_REGISTRATION_USER: 'user/register',

    // Profile
    URL_POST_DETAIL_USER: 'api/v1/user/detail',
    URL_UPDATE_DETAIL_USER: 'api/v1/user/update',

    // Chnage Password
    URL_POST_CHANGE_PASSWORD: 'api/v1/user/updatePassword',
    
    // Login
    URL_POST_LOGIN_USER: 'user/login',

    // Forgot Password
    URL_POST_FORGOT_PASSWORD: 'user/forgotpassword',

    // Kategori
    URL_GET_LISTS_KATEGORI: 'user/listKategoriGame',
    URL_POST_KOMENTAR: 'api/v1/komentar/add',
    
    // Sub Kategori
    URL_GET_LISTS_SUB_KATEGORI_BY_KATEGORI: 'user/listSubKategoriGameByKategori',

    // Jadwal Pertandingan
    URL_POST_LISTS_JADWAL_PERTANDINGAN_BY_KATEGORI: 'jadwal/listbykategori',
    URL_POST_LISTS_JADWAL_PERTANDINGAN_BY_KATEGORI_AND_SUB_KATEGORI: 'jadwal/listbykategoriandsub',
    URL_GET_LIST_ALL_JADWALPERTANDINGAN: 'jadwal/listtimeline',

    // Line Up Team
    URL_GET_LINE_UP_TEAM: 'user/listLineUpByTeamUser',

    // Article
    URL_GET_LIST_ARTIKEL: 'artikel/list',
    URL_POST_LISTS_ARTIKEL_WITH_COMMENT: 'artikel/detailwithkomentar',

    // Video
    URL_GET_LIST_VIDEO: 'user/listVideo',
}

export default Constant;