import { Layout } from 'antd';
import "./index.scss";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Route, Routes } from 'react-router-dom';
import '../../Assets/style/index.scss';
import Constant from "../../config/constans";
import * as listRoutes from "../../config/routes/listRoutes";
import { LandingPages, NotFound, PrivacyPolicy } from '../pages';

function App() {
  const [dataKategori, setDataKategori] = useState (null);

  useEffect(() => {
    getListKategoriGame()
  },[]);

  const getListKategoriGame = () => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI;
    axios
    .get(URL)
    .then((response) => {
      if (response.status === 200) {
        setDataKategori(response.data.Data)
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  const changeDataKategori = dataKategori ? dataKategori.map(item => ({ ...item, path: item.nama_kategori_game.toLowerCase().replace(/\s+/g, '-') })):null;
  
  return (
    <Layout className="web-score-quest">
      <Routes>
        <Route exact path={listRoutes.Default} element={<LandingPages dataKategori={dataKategori}/>} >
          {dataKategori ? changeDataKategori.map((item) => {
            return (
              <Route exact path={`/${item.path}`} />
            )
          }) : null}
          {dataKategori ? changeDataKategori.map((item) => {
            return (
              <Route exact path={`/${item.path}/line-up`} />
            )
          }) : null}
          <Route exact path="/line-up" />
          <Route exact path="/news" />
          <Route exact path="/news/:id" />
          <Route exact path="/highlights" />
          <Route exact path="/highlights/:id" />
          <Route exact path="/profile" />
        </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;



